[dir="rtl"] {
    .ms-account-loyalty {
        &__points-modal {
            .msc-modal__content {
                .msc-modal__header .msc-modal__title {
                    padding-right: 0;
                    padding-left: 25px;
                }
            }
        }
    }
}
