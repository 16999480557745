$cartline-lines-item-margin: 20px 0;
$cart-cartline-item-padding: 20px;
$cart-cartline-remove-button-margin: 20px;
$cart-cartline-product-width: 10px;
$cart-cartline-product-image-margin-right: 20px;
$cart-cartline-product-variant-name-padding-left: 5px;
$cartline-item-bopis-container-margin: 12px 0;
$cart-cart-line-bopis-container-line-height: 20px;
$cartline-item-bopis-shipping-margin-bottom: 10px;
$cartline-item-bopis-changestore-padding: 0;
$cartline-item-bopis-fulfillment-store-margin-top: 3px;
$cartline-item-bopis-btn-margin-top: 8px;
$cartline-item-bopis-method-icon-margin-right: 8px;
$cartline-item-discount-price-margin-right: 5px;
$cartline-item-product-savings-margin-left: 20px;
$cartline-item-product-savings-width: 120px;
$cart-cartline-product-quantity-width: 192px;
$cart-cartline-product-quantity-input-width: 80px;
$cart-cartline-product-quantity-spacing: 8px;
$cart-cartline-product-quantity-button-padding: 11px 0;
$cart-cartline-product-quantity-button-width: 48px;
$cart-quantity-input-padding: 13px 8px 9px 8px;
$cart-empty-cart-width: 190px;
$cart-line-padding-top: 10px;
$cart-line-card-height: 220px;
$cart-lines-group-heading-margin-top: 12px;
$cart-lines-group-heading-margin-left: 76px;
$cart-lines-group-fullfillment-store-heading-margin-top: 5px;
$cart-line-item-group-fullfillment-store-padding-top: 5px;
$cart-lines-group-fullfillment-store-margin-bottom: 14px;
$cart-lines-group-heading-margin-left-ct: 5px;

//style presets
:root {
    --msv-cart-border: #{$msv-gray-300};
    --msv-cart-font-color: var(--msv-font-primary-color);
    --msv-cart-font-size: var(--msv-body-font-size-m);
    --msv-cart-linewraper-count-font-size: var(--msv-body-font-size-s);

    // title
    --msv-cart-title-font-color: var(--msv-font-primary-color);
    --msv-cart-title-font-size: var(--msv-body-font-size-l);
    --msv-cart-discount-color: var(--msv-success-color);
    --msv-cart-discount-size: var(--msv-body-font-size-m);

    // link
    --msv-cart-btn-link-color: var(--msv-font-primary-color);
    --msv-cart-btn-link-size: var(--msv-body-font-size-m);
    --msv-cart-label-color: var(--msv-font-primary-color);
    --msv-cart-label-size: var(--msv-body-font-size-l);

    //button
    --msv-cart-quantity-btn-bg: #{$msv-white};
    --msv-cart-quantity-btn-border: var(--msv-btn-input-border-color);
    --msv-cart-quantity-input-bg: #{$msv-white};
    --msv-cart-quantity-input-color: var(--msv-font-primary-color);
    --msv-cart-quantity-input-border: var(--msv-btn-input-border-color);
    --msv-cart-quantity-input-size: var(--msv-body-font-size-l);

    // primary button
    --msv-cart-primary-btn-bg: var(--msv-accent-brand-color);
    --msv-cart-primary-btn-font-color: var(--msv-font-secondary-color);
    --msv-cart-primary-btn-border: var(--msv-accent-brand-color);

    // secondary button
    --msv-cart-secondary-btn-bg: var(--msv-secondary-button-background-color);
    --msv-cart-secondary-btn-font-color: var(--msv-font-primary-color);
    --msv-cart-secondary-btn-border: var(--msv-accent-brand-color);

    // group heading
    --msv-cart-lines-group-heading-font-color: var(--msv-font-primary-color);
    --msv-cart-lines-group-heading-font-size: var(--msv-body-font-size-l);
}

.msc-cart {
    &-lines-item {
        border-bottom: 1px solid var(--msv-cart-border);
        margin: $cartline-lines-item-margin;
        padding: $cart-cartline-item-padding;
        min-height: $cart-line-card-height;
    }

    &-line {
        display: flex;

        &__content {
            display: flex;
            width: 100%;
            flex-wrap: wrap;
        }

        &__price-editor-container {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            padding-top: 20px;

            &__input {
                width: 100%;
                max-width: 310px;
                height: 48px;
                font-size: 18px;
                line-height: 24px;
                margin-bottom: 20px;
            }

            &__actions {
                padding-left: 10px;
                padding-right: 10px;

                @media screen and (max-width: $msv-breakpoint-m) {
                    padding-left: unset;
                    padding-right: unset;
                    width: 100%;
                }

                &__price-editor-mode-selection {
                    width: 100%;

                    &__expand-modes-button {
                        @include secondary-button();
                        @include font-content-m();

                        height: unset;
                        width: 100%;

                        &::after {
                            @include msv-icon();
                            content: $msv-ChevronDown;
                            text-align: center;
                            vertical-align: text-bottom;
                            margin: 0 8px;
                        }
                    }

                    &__expand-modes-button[aria-expanded="true"] {
                        &::after {
                            content: $msv-ChevronUp;
                        }
                    }

                    .msc-popover {
                        background-color: var(--msv-header-bg);
                        background-clip: padding-box;
                        border: 1px solid $msv-gray-500;
                        display: block;
                        left: 0;
                        max-width: 276px;
                        position: absolute;
                        top: 0;
                        word-wrap: break-word;
                        z-index: 1060;

                        button {
                            align-items: center;
                            background-color: transparent;
                            border: transparent;
                            display: flex;
                            font-size: 16px;
                            line-height: 20px;
                            padding: 0 20px;
                            height: 48px;
                            cursor: pointer;
                            width: 100%;

                            &:hover {
                                text-decoration: none;
                            }
                        }

                        .msc-btn {
                            color: var(--msv-header-font-color);
                            font-weight: var(--msv-font-weight-normal);
                            height: 48px;
                        }

                        .msc-arrow {
                            display: block;
                            height: 0.5rem;
                            margin: 0 0.3rem;
                            position: absolute;
                            top: calc((0.5rem + 1px) * -1);
                            width: 1rem;

                            &::before,
                            &::after {
                                border-color: transparent;
                                border-style: solid;
                                border-width: 0 0.5rem 0.5rem 0.5rem;
                                content: "";
                                display: block;
                                position: absolute;
                            }

                            &::before {
                                border-bottom-color: $msv-gray-500;
                                top: 0;
                            }

                            &::after {
                                border-bottom-color: var(--msv-border-color);
                                top: 1px;
                            }
                        }
                    }
                }
            }
        }

        &-item {
            &-product-discount {
                @include font-content(var(--msv-font-weight-normal), var(--msv-cart-discount-size), $msv-line-height-m);
                color: var(--msv-cart-discount-color);
            }
        }

        &__product {
            flex: 1 1 $cart-cartline-product-width;

            &-inventory-label {
                @include font-content(var(--msv-font-weight-normal), var(--msv-cart-font-size), $msv-line-height-m);
                color: var(--msv-cart-font-color);
                display: block;
            }

            &-image {
                margin-right: $cart-cartline-product-image-margin-right;
            }

            &-variant {
                &-size,
                &-color,
                &-style,
                &-config {
                    .name {
                        padding-left: $cart-cartline-product-variant-name-padding-left;
                        color: var(--msv-cart-font-color);
                    }
                }
            }
        }

        &__promo-codes {
            display: inline-block;
        }

        &__product-quantity {
            flex: 0 0 $cart-cartline-product-quantity-width;

            &-label {
                @include font-content(var(--msv-font-weight-normal), var(--msv-cart-label-size), $msv-line-height-l);
                color: var(--msv-cart-label-color);
            }

            .quantity {
                .quantity-input {
                    @include form-input-el(var(--msv-cart-quantity-input-bg),var(--msv-cart-quantity-input-color));
                    font-size: var(--msv-cart-quantity-input-size);
                    border: 1px solid var(--msv-cart-quantity-input-border);
                    width: $cart-cartline-product-quantity-input-width;
                    display: inline-block;
                    margin-left: $cart-cartline-product-quantity-spacing;
                    margin-right: $cart-cartline-product-quantity-spacing;
                    -moz-appearance: textfield;
                    padding: $cart-quantity-input-padding;

                    &::-webkit-inner-spin-button,
                    &::-webkit-outer-spin-button {
                        -webkit-appearance: none;
                    }
                }

                .increment {
                    @include font-glyph-standard();
                    @include secondary-button(var(--msv-cart-secondary-btn-bg),
                    var(--msv-cart-secondary-btn-font-color), var(--msv-cart-secondary-btn-border), false);
                    @include add-icon($msv-Add, after);
                    padding: $cart-cartline-product-quantity-button-padding;
                    background: var(--msv-cart-quantity-btn-bg);
                    border-color: var(--msv-cart-quantity-btn-border);
                    min-width: auto;
                    width: $cart-cartline-product-quantity-button-width;

                    &.transition {
                        background: var(--msv-cart-quantity-btn-bg);
                    }

                    &.disabled {
                        cursor: default;
                        border-color: $msv-gray-300;
                        background-color: $msv-gray-300;
                        color: var(--msv-font-secondary-color);
                    }
                }

                .decrement {
                    @include font-glyph-standard();
                    @include secondary-button(var(--msv-cart-secondary-btn-bg),
                    var(--msv-cart-secondary-btn-font-color), var(--msv-cart-secondary-btn-border), false);
                    @include add-icon($msv-Remove, after);
                    padding: $cart-cartline-product-quantity-button-padding;
                    background: var(--msv-cart-quantity-btn-bg);
                    border-color: var(--msv-cart-quantity-btn-border);
                    min-width: auto;
                    width: $cart-cartline-product-quantity-button-width;

                    &.transition {
                        background: var(--msv-cart-quantity-btn-bg);
                    }

                    &.disabled {
                        cursor: default;
                        border-color: $msv-gray-300;
                        background-color: $msv-gray-300;
                        color: var(--msv-font-secondary-color);
                    }
                }
            }
        }

        &__product-price,
        &__product-savings {
            @include font-content(var(--msv-font-weight-normal), var(--msv-cart-discount-size), $msv-line-height-m);
            color: var(--msv-cart-discount-color);
            text-align: right;
            margin-left: $cartline-item-product-savings-margin-left;
            width: $cartline-item-product-savings-width;

            .msc-price {
                &__actual {
                    @include font-content(var(--msv-font-weight-normal),
                    var(--msv-cart-discount-size), $msv-line-height-m);
                }

                &.discount-value {
                    .msc-price__strikethrough {
                        @include font-content-m-strikethrough();
                        display: inline;
                        margin-right: $cartline-item-discount-price-margin-right;
                    }
                }
            }

            &-actual {
                .msc-price {
                    &__actual {
                        @include font-content(var(--msv-font-weight-bold),
                        var(--msv-cart-label-size), $msv-line-height-l);
                        display: block;
                        color: var(--msv-cart-label-color);
                    }

                    /* stylelint-disable-next-line no-descending-specificity -- Auto-suppressed. Please fix if possible. */
                    &__strikethrough {
                        color: var(--msv-cart-font-color);
                        display: none;
                    }
                }
            }
        }

        // hide extra price component
        &__product-price {
            display: none;
        }

        &__product-title {
            @include font-content(var(--msv-font-weight-bold), var(--msv-cart-title-font-size),$msv-line-height-l);
            @include vfi();
            color: var(--msv-cart-title-font-color);
        }

        &__product-variants,
        &__other-charges-label,
        &__freight-label,
        .shipping-value,
        .other-charge-value {
            @include font-content(var(--msv-font-weight-normal), var(--msv-cart-font-size), $msv-line-height-m);
            color: var(--msv-cart-font-color);
        }

        &__other-charges-label,
        &__freight-label {
            margin-right: $cart-cartline-product-variant-name-padding-left;
        }

        &__remove-item,
        &__add-to-order-template,
        &__add-to-wishlist {
            @include font-content(var(--msv-font-weight-light), var(--msv-cart-btn-link-size), $msv-line-height-m);
            @include button-link(var(--msv-cart-btn-link-color));
            @include vfi();
            margin-right: $cart-cartline-remove-button-margin;
            padding: 0;
            text-decoration: underline;

            &::after {
                content: "";
            }
        }

        &__add-to-order-template.disabled {
            color: $msv-gray-300;
        }

        &__bopis-container {
            @include font-content(var(--msv-font-weight-normal), var(--msv-cart-font-size), $msv-line-height-m);
            margin: $cartline-item-bopis-container-margin;
        }

        &__bopis-method {
            @include font-content(var(--msv-font-weight-normal), var(--msv-cart-label-size), $msv-line-height-l);

            .ship {
                @include add-icon($msv-StoreAlt, before);
                color: var(--msv-cart-label-color);

                &::before {
                    margin-right: $cartline-item-bopis-method-icon-margin-right;
                }
            }

            .pick-up {
                @include add-icon($msv-DeliveryTruck, before);

                &::before {
                    margin-right: $cartline-item-bopis-method-icon-margin-right;
                }
            }
        }

        &__bopis-shipping {
            align-self: baseline;
            margin-bottom: $cartline-item-bopis-shipping-margin-bottom;

            input {
                @include vfi();
            }
        }

        &__bopis-store-pickup {
            align-self: baseline;
        }

        &__bopis-changestore {
            @include button-link(var(--msv-cart-btn-link-color));
            @include font-content(var(--msv-font-weight-light), var(--msv-cart-btn-link-size), $msv-line-height-m);
            display: block;
            height: auto;
            margin-top: $cartline-item-bopis-btn-margin-top;
            padding: $cartline-item-bopis-changestore-padding;
            text-decoration-line: underline;
        }

        &__bopis-fullfilment {
            &-store {
                margin-top: $cartline-item-bopis-fulfillment-store-margin-top;

                @include font-content(var(--msv-font-weight-bold), var(--msv-cart-font-size), $msv-line-height-m);

                color: var(--msv-cart-font-color);
            }
        }

        &__bopis-btn {
            @include secondary-button(var(--msv-cart-secondary-btn-bg),
            var(--msv-cart-secondary-btn-font-color), var(--msv-cart-secondary-btn-border));
            margin-top: $cartline-item-bopis-btn-margin-top;
        }
    }

    &__empty-cart {
        .msc-cart-line {
            @include font-content(var(--msv-font-weight-light), var(--msv-cart-font-size), $msv-line-height-m);
            padding-top: $cart-line-padding-top;
        }

        width: $cart-empty-cart-width;

        .msc-cart__btn-backtoshopping {
            @include primary-button(var(--msv-cart-primary-btn-bg),
            var(--msv-cart-primary-btn-font-color), var(--msv-cart-primary-btn-border));
        }
    }

    &-lines-group {
        &-wraper {
            &__bopis {
                &-heading {  //msc-cart-lines-group-wraper__bopis-method
                    border-bottom: 1px solid var(--msv-cart-border);
                    position: relative;
                    top: 10px;

                    &-title {
                        display: flex;
                        flex: 1 1 100px;
                        margin-left: $cart-lines-group-heading-margin-left;
                        margin-bottom: $cart-lines-group-fullfillment-store-margin-bottom;

                        &-st {
                            @include font-content(var(--msv-font-weight-bold),
                            var(--msv-cart-font-size), $msv-line-height-m);
                            color: var(--msv-cart-font-color);
                            padding-top: $cart-line-item-group-fullfillment-store-padding-top;
                        }

                        &-ct {
                            @include font-content(var(--msv-font-weight-light),
                            var(--msv-cart-linewraper-count-font-size), $msv-line-height-m);
                            color: var(--msv-cart-font-color);
                            padding-top: $cart-line-item-group-fullfillment-store-padding-top;
                            margin-left: $cart-lines-group-heading-margin-left-ct;
                        }
                    }

                    &-pickup-icon {
                        @include add-icon($msv-cube, before);
                        position: absolute;
                        justify-content: center;
                        font-size: 28px;
                        padding-top: 10px;
                        padding-left: 20px;
                    }

                    &-shipping-icon,
                    &-email-icon,
                    &-ship-icon {
                        @include add-icon($msv-DeliveryTruck, before);
                        position: absolute;
                        justify-content: center;
                        font-size: 28px;
                        padding-top: 10px;
                        padding-left: 20px;
                    }

                    &-pickup,
                    &-shipping,
                    &-email,
                    &-ship {
                        color: var(--msv-cart-font-color);
                        margin-top: $cart-lines-group-heading-margin-top;
                        margin-left: $cart-lines-group-heading-margin-left;

                        @include font-content(var(--msv-font-weight-bold), var(--msv-cart-lines-group-heading-font-size),$msv-line-height-l);
                    }
                }

                &-changestore &-btn {
                    @include button-link(var(--msv-cart-btn-link-color));
                    @include font-content(var(--msv-font-weight-light), var(--msv-cart-btn-link-size), $msv-line-height-m);
                    display: block;
                    height: auto;
                    margin-top: $cartline-item-bopis-btn-margin-top;
                    padding: $cartline-item-bopis-changestore-padding;
                    text-decoration-line: underline;
                }
            }
        }
    }
}
